<template>
  <section>
      <div class="content-header">
        <h2>Book chapters</h2>
      </div>
      <div class="content-wrapper">
        <ul>
          <li><a href="http://repository.cityu.edu/bitstream/handle/20.500.11803/610/Chap23Supporting.pdf?sequence=3&isAllowed=y" target="_blank">Building Resilience in Adult Online Students</a> <a href="https://creativecommons.org/licenses/by/2.0/" target="_blank">(Creative Commons License: CC BY)</a></li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '05',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
